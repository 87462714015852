<template>
  <div class="container" v-if="initOk">
    <van-divider
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      个人资料
    </van-divider>
    <van-form @submit="onSubmit">
      <div v-if="id">
        <!-- <van-field
          v-model="isFullTime"
          name="isFullTime"
          label="就读方式"
          disabled
        /> -->
        <van-field v-model="name" name="name" label="姓名" disabled />
        <van-field v-model="idCard" name="idCard" label="身份证号码" disabled />
      </div>
      <div v-else>
        <!-- <van-field
          readonly
          clickable
          name="isFullTime"
          :value="isFullTime"
          label="就读方式"
          placeholder="请选择就读方式"
          @click="showIsFullTimePicker = true"
          :rules="[{ required: true, message: '请选择您的就读方式' }]"
        />
        <van-popup v-model="showIsFullTimePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="isFullTimeColumns"
            @confirm="onIsFullTimeConfirm"
            @cancel="showIsFullTimePicker = false"
          />
        </van-popup> -->
        <van-field
          v-model="name"
          name="name"
          label="姓名"
          placeholder="您的姓名"
          :rules="[{ required: true, message: '请输入您的真实姓名' }]"
        />
        <van-field
          v-model="idCard"
          name="idCard"
          label="身份证号码"
          placeholder="您的身份证号码"
          :rules="[{ required: true, message: '请输入您的身份证号码' }]"
        />
      </div>
      <van-field
        v-model="mobile"
        name="mobile"
        label="手机号码"
        type="digit"
        placeholder="您的手机号码"
        :rules="[{ required: true, message: '请输入您的手机号码' }]"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="area"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择您所在的地区' }]"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onAreaConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        v-model="address"
        name="address"
        label="详细地址"
        placeholder="您的详细地址（精确到门牌号）"
        :rules="[{ required: true, message: '请输入您的详细地址' }]"
      />
      <van-field
        readonly
        clickable
        name="religion"
        :value="religion"
        label="宗教信仰"
        placeholder="请选择宗教信仰"
        @click="showReligionPicker = true"
        :rules="[{ required: true, message: '请选择您的宗教信仰' }]"
      />
      <van-popup v-model="showReligionPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="religionColumns"
          @confirm="onReligionConfirm"
          @cancel="showReligionPicker = false"
        />
      </van-popup>
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        法定监护人或配偶
      </van-divider>
      <van-field
        v-model="contactName"
        name="contactName"
        label="姓名"
        placeholder="法定监护人或配偶姓名"
        :rules="[{ required: true, message: '请输入法定监护人或配偶姓名' }]"
      />
      <van-field
        v-model="contactPhone"
        name="contactPhone"
        label="联系电话"
        placeholder="请输入联系电话"
        :rules="[{ required: true, message: '请输入联系电话' }]"
      />
      <van-field
        v-model="contactJob"
        name="contactJob"
        label="工作单位"
        placeholder="工作单位"
        :rules="[{ required: true, message: '请输入工作单位' }]"
      />
      <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        直系亲属
      </van-divider>
      <van-field
        v-model="contactName2"
        name="contactName2"
        label="姓名"
        placeholder="直系亲属姓名"
        :rules="[{ required: true, message: '请输入直系亲属姓名' }]"
      />
      <van-field
        v-model="contactPhone2"
        name="contactPhone2"
        label="联系电话"
        placeholder="请输入联系电话"
        :rules="[{ required: true, message: '请输入联系电话' }]"
      />
      <van-field
        v-model="contactJob2"
        name="contactJob2"
        label="工作单位"
        placeholder="工作单位"
        :rules="[{ required: true, message: '请输入工作单位' }]"
      />
      <!-- <van-divider
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
          marginBottom: '10px',
        }"
      >
        资料上传
      </van-divider>
      <div
        style="
          text-align: center;
          font-size: 12px;
          color: #ccc;
          margin-bottom: 10px;
        "
      >
        身份证正反面、退役军人证
      </div>
      <van-field
        name="uploader"
        :rules="[{ required: true, message: '请选择照片' }]"
      >
        <template #input>
          <van-uploader
            v-model="uploader"
            :after-read="afterRead"
            multiple
            :max-count="3"
            :max-size="10 * 1024 * 1024"
            @oversize="onOversize"
          />
        </template>
      </van-field> -->
      <div style="margin: 16px">
        <van-button round block type="danger" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Notify } from "vant";
import { areaList } from "@vant/area-data";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      id: undefined,
      isFullTime: "",
      isFullTimeColumns: ["在校住宿就读", "半工半读"],
      showIsFullTimePicker: false,
      name: "",
      idCard: "",
      mobile: "",
      area: "",
      showArea: false,
      areaList: areaList,
      address: "",
      religion: "",
      religionColumns: ["无", "基督教", "伊斯兰教", "佛教", "道教", "其它"],
      showReligionPicker: false,
      contactName: "",
      contactPhone: "",
      contactJob: "",
      contactName2: "",
      contactPhone2: "",
      contactJob2: "",
      uploader: [],
    };
  },
  created() {
    this.findUserProfile();
  },
  methods: {
    findUserProfile() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.findUserProfile().then((res) => {
        console.log(res);
        this.id = res.id;
        if (res.isFullTime == undefined) {
          this.isFullTime = "在校住宿就读";
        } else {
          this.isFullTime =
            res.isFullTime == true ? "在校住宿就读" : "半工半读";
        }
        this.name = res.name;
        this.idCard = res.idCard;
        this.mobile = res.mobile;
        this.area = res.area;
        this.address = res.address;
        this.religion = res.religion;
        this.contactName = res.contactName;
        this.contactPhone = res.contactPhone;
        this.contactJob = res.contactJob;
        this.contactName2 = res.contactName2;
        this.contactPhone2 = res.contactPhone2;
        this.contactJob2 = res.contactJob2;
        this.uploader = [];
        if (res.imgs) {
          JSON.parse(res.imgs).forEach((r) => {
            this.uploader.push({ url: r });
          });
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onIsFullTimeConfirm(value) {
      this.isFullTime = value;
      this.showIsFullTimePicker = false;
    },
    onAreaConfirm(values) {
      this.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    onReligionConfirm(value) {
      this.religion = value;
      this.showReligionPicker = false;
    },
    //上传
    onOversize(file) {
      console.log(file);
      Toast.fail("文件大小不能超过 10MB");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("file", file.file);
      api.commonUpload(formData).then((res) => {
        if (res.state == "ok") {
          file.status = "succeed";
          file.message = "上传成功";
          file.url = res.msg;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //上传END
    onSubmit(values) {
      if (values.isFullTime == "在校住宿就读") {
        values.isFullTime = true;
      } else {
        values.isFullTime = false;
      }
      let imgs = [];
      this.uploader.forEach((u) => {
        imgs.push(u.url);
      });
      values.uploader = [];
      values.imgs = JSON.stringify(imgs);
      api.userProfileSaveOrUpdate(values).then((res) => {
        if (res.state == "ok") {
          Toast.success("绑定成功");
          // this.findUserProfile();
          if (res.msg == "扩招专升本") {
            this.$router.push({ path: "/checkin/fullday", query: {} });
          } else {
            this.$router.push({ path: "/checkin", query: {} });
          }
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
      console.log("submit", values);
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
</style>
